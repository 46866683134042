import React from 'react';
import logo from './logo.svg';
import './App.css';
import Banko from './Components/Banko/Banko';

function App() {
  return (
    <div className="Bankoplader">
     <Banko></Banko>
    </div>
  );
}

export default App;
