import { createElement, useContext, useEffect, useState } from "react";
import "./Banko.scss";

enum LayoutType{
    THREE_PAGE,
    SIX_PAGE
}

const Banko = () => {

    const [generateCount, setGenerateCount] = useState<number>(1)
    const [layoutType, setLayoutType] = useState<string>("six-tables-layout")

    const getNumbersInRange = (from: number, to: number, count: number, takenNumbers: Array<number>): Array<number> => {

        let arr = Array<number>()
        for (let i = 0; i < count; ++i) {
            let current = Math.floor(Math.random() * (to - from + 1) + from)
            while (arr.indexOf(current) > -1 || takenNumbers.indexOf(current) > -1 || sameLeadingNumber(arr, current)) {
                current = Math.floor(Math.random() * (to - from + 1) + from)
            }
            arr.push(current)
        }
        return arr.sort((a, b) => a - b);

    }

    const sameLeadingNumber = (arr: Array<number>, currentNumber: number): boolean => {
        try {
            return arr.some(item => {
                if (currentNumber.toString().length === 1 && item.toString().length === 1) return true;
                if (currentNumber.toString().substr(0, 1) == "9" && item.toString().substr(0, 1) == "8" || currentNumber.toString().substr(0, 1) == "8" && item.toString().substr(0, 1) == "9") return true
                return currentNumber.toString().substr(0, 1) == item.toString().substr(0, 1)
            })
        }
        catch {
            return false
        }
    }

    const hasEmptyCells = (table: HTMLTableElement): boolean => {

        let rows = Array.from(table.rows)

        let arrs = Array<Array<string>>()


        rows.forEach(item => {

            let cols = Array.from(item.cells)
            arrs.push(cols.map(item => item.innerText))

        })

        for (let i = 0; i < 9; ++i) {

            let val1 = arrs[0][i]
            let val2 = arrs[1][i]
            let val3 = arrs[2][i]

            if (val1 == "" && val2 == "" && val3 == "") {
                return true
            }
        }

        return false
    }

    const sortColumns = async (table: HTMLTableElement) => {

        let cells = Array<HTMLTableCellElement>()
        let arr = Array.from(table.rows)
        arr.forEach(item => {

            let current = Array.from(item.cells)
            current.forEach(inner => {
                cells.push(inner)
            })
        })

        sortValues([cells[0], cells[9], cells[18]])
        sortValues([cells[1], cells[10], cells[19]])
        sortValues([cells[2], cells[11], cells[20]])
        sortValues([cells[3], cells[12], cells[21]])
        sortValues([cells[4], cells[13], cells[22]])
        sortValues([cells[5], cells[14], cells[23]])
        sortValues([cells[6], cells[15], cells[24]])
        sortValues([cells[7], cells[16], cells[25]])
        sortValues([cells[8], cells[17], cells[26]])
    }

    const sortValues = (arr: Array<HTMLTableCellElement>) => {

        let values = [...arr.map(item => item.innerText)]

        let count = values.filter(item => item.length > 0).length

        if (count === 1) return

        if (count === 2) {

            let val1 = values[0]
            let val2 = values[1]
            let val3 = values[2]

            if (val1.length > 0 && val2.length > 0) {
                if (Number(val1) > Number(val2)) {
                    [val1, val2] = [val2, val1]
                    arr[0].innerText = val1
                    arr[1].innerText = val2
                }
            }
            else if (val1.length > 0 && val3.length > 0) {
                if (Number(val1) > Number(val3)) {
                    [val1, val3] = [val3, val1]
                    arr[0].innerText = val1
                    arr[2].innerText = val3
                }
            }
            else if (val2.length > 0 && val3.length > 0) {
                if (Number(val2) > Number(val3)) {
                    [val2, val3] = [val3, val2]
                    arr[1].innerText = val2
                    arr[2].innerText = val3
                }
            }

        }

        if (count === 3) {
            let sorted = [...values].map(item => Number(item)).sort((a, b) => a - b)
            arr[0].innerText = sorted[0] === 0 ? "" : sorted[0].toString()
            arr[1].innerText = sorted[1] === 0 ? "" : sorted[1].toString()
            arr[2].innerText = sorted[2] === 0 ? "" : sorted[2].toString()
        }
    }

    const hanleGenerateBankoPladeClick = () => {

        for (let i = 0; i < generateCount; ++i){
            generateBankoPlade("bankoplade")
        }
      
    }

    const generateBankoPlade = (elementId: string): HTMLElement => {

        let takenNumbers = Array<number>()

        let tableElement = document.createElement("table")
        tableElement.classList.add(`banko-table`)
        tableElement.classList.add(`${layoutType}`)

        for (let row = 0; row < 3; ++row) {

            let rowElement = document.createElement("tr")

            let numbers = getNumbersInRange(1, 90, 5, takenNumbers)

            takenNumbers.push(...numbers)

            for (let col = 0; col < 90; col += 10) {

                let target = numbers.find(item => item >= col && item < col + 10)

                if (numbers[4] === 90 && col >= 80) {
                    target = 90
                }

                let colElement = document.createElement("td")

                colElement.innerText = target !== undefined ? target.toString() : ""

                rowElement.appendChild(colElement)
            }

            tableElement.appendChild(rowElement)
        }



        if (hasEmptyCells(tableElement)) {
            generateBankoPlade(elementId)
        }
        else {

            sortColumns(tableElement)
            document.getElementById(elementId)?.appendChild(tableElement)
        }

        return tableElement
    }

    const generateCountChanged = (e:any)=> {
        setGenerateCount(e.target.value)
    }

    const handleLayoutChange = (e:any)=> {

        setLayoutType(e.target.value)
    }

    const print = ()=> {
        window.print()
    }
    return (
        <div id="bankoContainer">
            <div id="bankoNavbar">
                <button className="banko-button" onClick={hanleGenerateBankoPladeClick}>Opret plade</button>          
                <button className="banko-button" onClick={print}>print plader</button> 
                <input className="banko-input" type="number" min="1" max="60" onChange={generateCountChanged} value={generateCount} placeholder="antal plader"></input>
                <select className="banko-select" onChange={handleLayoutChange}>
                    <option selected={layoutType === 'six-tables-layout'} value="six-tables-layout">6 pr side (liggende)</option>
                    <option selected={layoutType === 'three-tables-layout'} value="three-tables-layout">4 pr side (stående)</option>
                </select>
            </div>

            <div id="bankoplade"></div>


        </div>
    )
}

export default Banko;


